import { ReactNode } from 'react'

interface ContainerProps {
  wide?: boolean,
  className?: string,
  children: ReactNode
}

const Container: React.FC<ContainerProps>  = ({children, className = '', wide = false}) => {
  return (
    <div className={`${wide ? '' : 'max-w-screen-2xl mx-auto p-4 md:px-8'} ${className}`}>
      {children}
    </div>
    )
}

export default Container
