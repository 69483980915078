import { Translations, useTranslations } from '../../hooks/useTranslations'
import ContactCard from '../ContactCard'

const translations: Translations = {
  ceo: {
    fi: 'Toimitusjohtaja',
    en: 'CEO'
  }
}

const TeroMalkki = () => {
  const translated = useTranslations(translations)

  return (
    <ContactCard
      imageUrl='/img/company/CEO.png'
      imageAlt='Nordic Coding Factory CEO'
      name='Tero Malkki'
      title={translated.ceo}
      contactDetails={[]}
      socialLinks={[{
        linkUrl: 'https://www.linkedin.com/in/tero-malkki-59534032/',
        iconAlt: 'LinkedIn profile',
        iconUrl: '/img/company/linkedin.svg'
      }]}
    />
  )
}

export default TeroMalkki
