import { useEffect, useState } from 'react'
import { MainNavState, mainNavState } from '../components/header/MainNavState'

export const useMainNavState = () => {
  const [state, setState] = useState(MainNavState.static)

  useEffect(() => {
    const subscription = mainNavState.subscribe(newState => {
      setState(newState)
    })

    return subscription.unsubscribe
  }, [])

  return state
}
