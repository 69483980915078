import ContactUs from '../components/ContactUs'
import Container from '../components/Container'
import Page from '../components/Page'
import Title from '../components/Title'
import { Translations, useTranslations } from '../hooks/useTranslations'

const translations: Translations = {
  company: {
    fi: 'Yritys',
    en: 'Company'
  }
}

const Company = () => {
  const translated = useTranslations(translations)

  return (
    <Page title={translated.company} titleBackground='bg-company'>
      <Container className='mt-12 mb-24'>
        <Title size={2}>Nordic Coding Factory</Title>

        <p className='text-xl font-montserratLight mt-8 mb-12'>
          Olemme suomalainen konsultointiyritys, jonka asiantuntijoilla on laaja kokemus ohjelmistoprojekteista.
          Autamme asiakkaitamme tuottamaan laadukkaita ja kestäviä digitaalisia palveluita.
          Olemme olleet mukana toteuttamassa useita tunnettuja julkisia palveluita sekä yritysten ohjelmistoratkaisuja.
        </p>

        <Title size={3} className='text-2xl mt-10 mb-4 font-medium'>Kestävä ohjelmistokehitys</Title>
        <p className='text-lg mb-6'>
          Tavoiteenamme on sujuvoittaa ihmisten arkea tuottamalla heille merkityksellisiä ja käyttäjäystävällisiä digitaalisia palveluita.
          Pyrimme saavuttamaan tavoitteemme auttamalla asiakkaitamme modernisoimaan ja digitalisoimaan organisaationsa toimintaa ja tuottamiaan palveluita - kestävällä tavalla.
          Avaa tähän lyhyesti mitä on kestävä ohjelmistokehitys ja miten se toteutuu yrityksessämme?
        </p>

        <p className='text-lg mb-6'>Tähän lisää sisältöä. Yksinkertaisuudesta.</p>

        <Title size={3} className='text-2xl mt-10 mb-4 font-medium'>Arvot</Title>
        <p className='text-lg mb-6'>
          Arvot luovat perustan yrityskulttuurillemme. Tähän lyhyesti yhtiön arvoista; Yhteistyö, kunnioitus ja vastuullisuus. Arvot ohjaavat jokapäiväistä toimintaamme.
        </p>

        <div className='w-full grid grid-cols-1 md:grid-cols-2 md:gap-y-12'>
          <div className='h-full bg-teamwork bg-center bg-cover hidden md:block'></div>
          <div className='bg-gray-100 p-8 lg:p-24 min-h-fit md:h-96'>
            <h4 className='text-xl mb-4 font-semibold'>Yhteistyö</h4>
            <p>
              Yrityksen tärkein voimavara on sen henkilöstö. Meillä panostetaan yhteistyöhön. Lisää yhteistyöstä, miten se näkyy arjessa asiakastyössä ja esim läpinäkyvyydestä.
            </p>
          </div>

          <div className='md:bg-gray-100 p-8 lg:p-24 min-h-fit md:h-96'>
            <h4 className='text-xl mb-4 font-semibold'>Kunnioitus</h4>
            <p>
              Yksi positiivisen työyhteisön peruspilareista on kunnioitus kollegoita, asiakkaita ja muita sidosryhmiä kohtaan.
              Jokainen ansaitsee tulla kohdelluksi reilusti ja kunnioittavasti tilanteesta riippumatta.
            </p>
          </div>
          <div className='h-full bg-respect bg-center bg-cover hidden md:block'></div>

          <div className='h-full bg-responsibility bg-top bg-cover hidden md:block'></div>
          <div className='bg-gray-100 p-8 lg:p-24 min-h-fit md:h-96'>
            <h4 className='text-xl mb-4 font-semibold'>Vastuullisuus</h4>
            <p>
              Meillä on vahva vastuuntunto sekä omasta toiminnastamme että sen vaikutuksesta kaikkeen meitä ympäröivään.
              Otamme työssämme huomioon kestävän ohjelmistokehityksen periaatteet sekä turvallisuuteen liittyvät seikat.

            </p>
          </div>
        </div>
      </Container>

      <ContactUs />
    </Page>
  )
}

export default Company
