import { XIcon } from '@heroicons/react/solid'
import { Translations, useTranslations } from '../../hooks/useTranslations'
import Container from '../Container'
import LanguageSelector from '../LanguageSelector'
import { Link, NavLink } from '../Link'

type HamburgerMenuProps = {
  closeCallback: () => void
}

const translations: Translations = {
  home: {
    fi: 'Etusivu',
    en: 'Home'
  },
  services: {
    fi: 'Palvelut',
    en: 'Services'
  },
  company: {
    fi: 'Yritys',
    en: 'Company'
  }
}

const HamburgerMenu = ({closeCallback}: HamburgerMenuProps) => {
  const translated = useTranslations(translations)

  return (
    <div className='fixed w-full h-full top-0 left-0 h-screentext-sky-700 z-100 bg-white pb-12'>
      <Container className="p-4 md:px-8">
        <div className='flex justify-between'>
          <Link to="/" noUnderline onClick={closeCallback}><img src='/img/logo.svg' alt='Nordic Coding Factory' className='h-10 sm:h-12 inline-block' /></Link>
          <XIcon className="h-10 sm:h-12 w-10 sm:w-12 cursor-pointer text-sky-700" onClick={closeCallback}></XIcon>
        </div>

        <ul className="grid grid-cols-1 gap-y-8 justify-items-center mt-28 text-xl">
          <li onClick={closeCallback}><NavLink to="/">{translated.home}</NavLink></li>
          <li onClick={closeCallback}><NavLink to="/services">{translated.services}</NavLink></li>
          <li onClick={closeCallback}><NavLink to="/company">{translated.company}</NavLink></li>
          <li className="mt-8"><LanguageSelector /></li>
        </ul>
      </Container>
    </div>
  )
}

export default HamburgerMenu