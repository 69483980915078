import { ReactNode } from 'react'

interface TitleProps {
  size: 2 | 3
  className?: string
  children: ReactNode
}

const Title: React.FC<TitleProps> = ({size, children, className}) => {
  switch (size) {
    case 2:
      return <h2 className={`text-3xl text-gray-700 lg:text-4xl font-montserratSemiBold ${className ?? ''}`}>{children}</h2>
    case 3:
    default:
      return <h3 className={`text-2xl lg:text-3xl font-montserratSemiBold ${className ?? ''}`}>{children}</h3>
  }


}

export default Title
