import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import MainNavigation from './header/Header'
import ScrollToTop from './ScrollToTop'

const Layout = () => {
  return (
    <div className='font-montserratRegular'>
      <ScrollToTop />
      <MainNavigation />
      <main className="z-0"><Outlet /></main>
      <Footer />
    </div>
  )
}

export default Layout
