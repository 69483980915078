import { Translations, useTranslations } from '../../hooks/useTranslations'
import Title from '../Title'

const translations: Translations = {
  ourServices: {
    fi: 'Palvelumme',
    en: 'Our services'
  },
  consulting: {
    fi: 'Konsultointi',
    en: 'Consulting'
  },
  consultingContent: {
    fi: 'Konsultoinnin tavoitteena on auttaa asiakasta saavuttamaan tehokkuutta, skaalautuvuutta ja laatua ohjelmistoprojekteissaan, sekä varmistaa, että projektin tavoitteet ja vaatimukset täyttyvät. Autamme asiakkaitamme parantamaan ohjelmistokehitysprosessejaan ja varmistamaan projektien onnistumisen.',
    en: 'The goal of consulting is to help the customer achieve efficiency, scalability, and quality in their software projects, as well as to ensure that the project goals and requirements are met. We help our customers improve their software development processes and ensure the success of their projects.'
  },
  softwareDevelopment: {
    fi: 'Ohjelmistokehitys',
    en: 'Software development'
  },
  softwareDevelopmentContent: {
    fi: 'Asiantuntijamme ovat erittäin kokeneita ja taitavia sekä frontend- että backend-kehityksessä. Heidän osaamisensa ansiosta voimme tarjota asiakkaillemme parhaita mahdollisia ohjelmistokehitysratkaisuja. Meille tutuimpia teknologioita ovat mm. Java, Spring, Node.js, TypeScript, Angular ja React.',
    en: '"Our experts are highly experienced and skilled in both frontend and backend development. Thanks to their expertise, we can provide our customers with the best possible software development solutions. Some of the technologies we are most familiar with include Java, Spring, Node.js, TypeScript, Angular and React.'
  },
  devOps: {
    fi: 'Secure DevOps',
    en: 'Secure DevOps'
  },
  devOpsContent: {
    fi: 'DevOpsin ytimenä on turhien työvaiheiden karsiminen ja kaiken toistuvan automatisointi. Meillä on vahva osaaminen ja kokemus Secure DevOps-toimintamallista. Kehityksen ja tuotannon tiivis yhteistyö sekä tietoturvan huomioiminen ovat keskeinen osa lähes kaikkia projektejamme.',
    en: 'DevOps revolves around streamlining work processes by removing unnecessary steps and automating repetitive tasks. We have strong expertise and experience in the Secure DevOps operating model. Close collaboration between development and production, as well as consideration for security, are key parts of almost all of our projects.'
  }
}

const HomeServices = () => {
  const translated = useTranslations(translations)

  return (
    <section className="text-black mt-24 xl:mt-32 mb-24 ">
      <Title size={2} className="mb-4">{translated.ourServices}</Title>
      <div className='w-full grid grid-cols-1 xl:grid-cols-3'>
        <div className='flex flex-row xl:flex-col gap-4 p-8 bg-gray-100'>
          <img src='/img/services/consulting-icon.svg' className='mx-auto h-32 w-32 xl:h-48 xl:w-48 hidden md:block' alt={translated.consulting} />
          <div className='flex-1 pl-0 md:pl-4 xl:pl-0'>
            <h4 className='text-xl mb-4 font-semibold'>{translated.consulting}</h4>
            <p>{translated.consultingContent}</p>
          </div>
        </div>

        <div className='flex flex-row xl:flex-col gap-4 p-8 bg-none xl:bg-gray-100'>
          <img src='/img/services/software-development-icon.svg' className='mx-auto h-32 w-32 xl:h-48 xl:w-48 hidden md:block' alt={translated.softwareDevelopment} />
          <div className='flex-1 pl-0 md:pl-4 xl:pl-0'>
            <h4 className='text-xl mb-4 font-semibold'>{translated.softwareDevelopment}</h4>
            <p>{translated.softwareDevelopmentContent}</p>
          </div>
        </div>

        <div className='flex flex-row xl:flex-col gap-4 p-8 bg-gray-100'>
          <img src='/img/services/devops-icon.svg' className='mx-auto h-32 w-32 xl:h-48 xl:w-48 hidden md:block' alt={translated.devOps} />
          <div className='flex-1 pl-0 md:pl-4 xl:pl-0'>
            <h4 className='text-xl mb-4 font-semibold'>{translated.devOps}</h4>
            <p>{translated.devOpsContent}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeServices