import { ReactNode, useEffect } from 'react'
import { MainNavState, mainNavState } from './header/MainNavState'
import PageTitle from './PageTitle'

type PageProps = {
  readonly title: string,
  readonly titleBackground?: string,
  readonly titleHighlightColor?: string,
  readonly children: ReactNode
}

const Page: React.FC<PageProps> = ({ title, titleBackground, titleHighlightColor, children }) => {
  useEffect(() => {
    mainNavState.next(MainNavState.scrolled)
  }, [])

  return (
    <main className="pt-18 sm:pt-20">
      <PageTitle title={title} background={titleBackground} highlightColor={titleHighlightColor} />
      {children}
    </main>
  )
}

export default Page
