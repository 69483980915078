
interface SocialLink {
  linkUrl: string
  iconUrl: string
  iconAlt: string
}

type ContactCardProps = {
  imageUrl: string
  imageAlt: string
  name: string
  title: string
  contactDetails: ReadonlyArray<string>
  socialLinks: ReadonlyArray<SocialLink>
}

const ContactCard: React.FC<ContactCardProps> = ({imageUrl, imageAlt, name, title, contactDetails, socialLinks}) => {
  return (
    <section className='w-full sm:w-128'>
      <img src={imageUrl} alt={imageAlt} className='full-w'/>
      <div className='bg-white px-6 py-8'>
        <h3 className='text-xl font-montserratLight font-semibold'>{name}</h3>
        <p className='text-sm font-montserratLight pb-4'>{title}</p>
        {contactDetails.map((contact, index) => (
          <p key={index} className='pb-2'>{contact}</p>
        ))}
        <div className='full-w grid justify-end'>
          {socialLinks.map((socialLink, index) => (
            <a key={index} href={socialLink.linkUrl} target="_blank" rel="noreferrer">
              <img src={socialLink.iconUrl} className='h-10 w-10 hover:brightness-75' alt={socialLink.iconAlt} />

            </a>
          ))}
        </div>
      </div>
    </section>
  )

}

export default ContactCard