import Container from '../components/Container'
import Page from '../components/Page'
import Title from '../components/Title'
import { Translations, useTranslations } from '../hooks/useTranslations'

const translations: Translations = {
  towardsSustainability: {
    fi: 'Kohti kestävää ohjelmistokehitystä',
    en: '*EN*Kohti kestävää ohjelmistokehitystä'
  }
}

const Sustainability = () => {
  const translated = useTranslations(translations)

  return (
    <Page title={translated.towardsSustainability} titleBackground='bg-sustainability' titleHighlightColor='bg-emerald-700'>
      <Container>
        <p className='text-xl my-8'>
          Teknologia on nykyään jatkuvasti läsnä lähes jokaisen arjessa. Ihmiset käyttävät laitteita ja sovelluksia yhä enemmissä määrin niin työpaikoilla kuin vapaa-aikanakin.
          Modernin teknologian jatkuvasti lisääntyvän käytön myötä lähes jokaisesta yrityksestä - alaan katsomatta - on internetaikakauden aikana muovautunut teknologiayritys.
        </p>
        <p className='text-xl my-8'>
          Digitalisaatio on maailmanlaajuinen ilmiö, joka on vaikuttanut erityisesti datan määrän räjähdysmäiseen kasvuun. Kasvun seurauksena data ja palvelut ovat siirtyneet
          yritysten omilta palvelimilta pilvipalvelun tarjoajien jättimäisiin hyperskaalautuviin konesaleihin. Datan määrän kasvu on myötävaikuttanut mm. myös datan siirtoa
          tukevan infrastruktuurin, kuten 5G verkon, käyttöönottoa.
        </p>
        <Title size={2}>Mitä on kestävä ohjelmistokehitys?</Title>
        <p className='text-xl my-8'></p>
      </Container>
    </Page>
  )
}

export default Sustainability
