import { useEffect, useState } from 'react'
import { Subject, takeUntil } from 'rxjs'
import { defaultLanguage, Language, languageService } from '../services/LanguageService'

interface Translation {
  fi: string
  en: string
}

export type Translations = {[key: string]: Translation}

type TranslationResults = {[key: string]: string}

function getTranslations(translations: Translations, language: Language): TranslationResults {
  return Object.keys(translations).reduce((results, key) => {
    results[key] = translations[key][language]
    return results
  }, {} as TranslationResults)
}

export const useTranslations = (translations: Translations): TranslationResults => {
  const [state, setState] = useState(getTranslations(translations, defaultLanguage))

  useEffect(() => {
    const cleanup = new Subject<never>()

    languageService.language$.pipe(takeUntil(cleanup)).subscribe(language => {
      setState(getTranslations(translations, language))
    })

    return function asdf() {
      cleanup.complete()
    }
  }, [translations])

  return state
}

