import Container from './Container'

type PageTitleProps = {
  readonly title: string
  readonly background?: string
  readonly highlightColor?: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title, background = 'bg-title-default', highlightColor = 'bg-sky-700' }) => {
  return (
    <div className={`${background} bg-cover bg-center h-128`}>
      <Container className='h-full grid justify-items-start items-center text-center'>
        <h1 className={`${highlightColor} text-white uppercase text-3xl p-6 font-montserratSemiBold`}>{title}</h1>
      </Container>
    </div>
  )
}

export default PageTitle
