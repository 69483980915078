import { useEffect, useState } from 'react'

export const usePageScrolled = (): number => {
  const [pageScrolled, setPageScrolled] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
      setPageScrolled(scrollTop)
    }

    window.addEventListener('scroll', handleScroll)
  }, [])

  return pageScrolled
}
