import Container from '../components/Container'
import Page from '../components/Page'
import { Translations, useTranslations } from '../hooks/useTranslations'

const translations: Translations = {
  privacyPolicy: {
    fi: 'Tietosuojaseloste',
    en: 'Privacy policy'
  }
}

const PrivacyPolicy = () => {
  const translated = useTranslations(translations)

  return (
    <Page title={translated.privacyPolicy} titleBackground='bg-privacy-policy'>
      <Container>
      </Container>
    </Page>
  )
}

export default PrivacyPolicy
