import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import { getRoutes } from './config/RoutesConfig'
import Home from './pages/Home'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />

        {getRoutes().map((route, index) => (<Route key={index} path={route.url} element={route.component} />))}

        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  )
}

export default App
