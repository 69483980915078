import { GlobeAltIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useClickOutside } from '../hooks/useClickOutside'
import { Translations, useTranslations } from '../hooks/useTranslations'
import { Language, languageService } from '../services/LanguageService'

const translations: Translations = {
  language: {
    fi: 'Suomi',
    en: 'English'
  }
}

type LanguageSelectorProps = {
  responsive?: boolean
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({responsive}) => {
  const [ showDropdown, setShowDropdown ] = useState(false)
  const translated = useTranslations(translations)
  const dropdownRef =  useClickOutside<HTMLDivElement>(() => {
    setShowDropdown(false)
  })

  const toggleDropdown = (event: React.MouseEvent) => {
    event.preventDefault()
    setShowDropdown(!showDropdown)
  }

  const changeLanguage = (language: Language) => {
    languageService.language = language
    setShowDropdown(false)
  }

  const buttonStyles = 'hover:border-current border-2 border-transparent rounded py-2 px-8 w-44'
  const responsiveButtonStyles = 'hover:border-current border-2 border-transparent rounded py-2 text-sm px-4 w-28 md:px-8 md:rounded md:w-44 md:text-lg'
  const dropdownStyles = 'bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 text-sm'
  const responsiveDropdownStyles = 'bg-white divide-y divide-gray-100 rounded shadow w-28 dark:bg-gray-700 text-sm md:w-44 md:text-md'


  return (
    <div ref={dropdownRef} >
      <button className={responsive ? responsiveButtonStyles : buttonStyles} onClick={toggleDropdown}>
        <GlobeAltIcon className="x-4 h-4 inline -mt-1"></GlobeAltIcon> {translated.language}
      </button>

      <div id="dropdown" className={`z-10 ${!showDropdown ? 'hidden' : ''} ${responsive ? responsiveDropdownStyles : dropdownStyles}`}>
        <ul className="py-1 text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
          <LanguageSelection name='English' clickAction={() => changeLanguage(Language.EN)}></LanguageSelection>
          <LanguageSelection name='Suomi' clickAction={() => changeLanguage(Language.FI)}></LanguageSelection>
        </ul>
      </div>
    </div>
  )
}

interface LanguageSelectionProps {
  name: string,
  clickAction: () => void
}

const LanguageSelection = ({name, clickAction}: LanguageSelectionProps) => {
  return (
    <li>
      <button className="text-left block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full" onClick={clickAction}>{name}</button>
    </li>
  )
}

export default LanguageSelector