import { Translations, useTranslations } from '../hooks/useTranslations'
import TeroMalkki from './contacts/TeroMalkki'
import Container from './Container'
import Title from './Title'

const translations: Translations = {
  contact: {
    fi: 'Kiinnostuitko? Ota yhteyttä',
    en: 'Interested? Contact us'
  },
  consulting: {
    fi: 'Konsultointi',
    en: 'Consulting'
  },
  softwareDevelopment: {
    fi: 'Ohjelmistokehitys',
    en: 'Software development'
  },
  devOps: {
    fi:'Secure DevOps',
    en: 'Secure DevOps'
  }
}

const ContactUs = () => {
  const translated = useTranslations(translations)

  return (
    <Container className="bg-sky-50" wide>
      <Container>
        <section className="py-12">
          <Title size={2} className="xl:col-span-2 text-sky-700">{translated.contact}</Title>
          <div className="mt-12">
            <TeroMalkki />
          </div>
        </section>
      </Container>
    </Container>
  )
}

export default ContactUs
