import { LocalizedString } from '../models/LocalizedString'
import Company from '../pages/Company'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import Services from '../pages/Services'
import Sustainability from '../pages/Sustainability'

export const routesConfig: LocalizedRoutes = {
  company: {
    url: {
      en: '/company',
      fi: '/yritys'
    },
    component: <Company />
  },
  services: {
    url: {
      en: '/services',
      fi: '/palvelut'
    },
    component: <Services />
  },
  privacyPolicy: {
    url: {
      en: '/privacy-policy',
      fi: '/tietosuoja'
    },
    component: <PrivacyPolicy />
  },
  sustainability: {
    url: {
      en: '/sustainability',
      fi: '/kestava-kehitys'
    },
    component: <Sustainability />
  }
}

export function getRoutes(): Route[] {
  return Object.values(routesConfig).flatMap((route: LocalizedRoute) => Object.values(route.url).map(url => ({ url, component: route.component })))
}

interface LocalizedRoutes {
  company: LocalizedRoute,
  services: LocalizedRoute,
  privacyPolicy: LocalizedRoute,
  sustainability: LocalizedRoute
}

interface LocalizedRoute {
  url: LocalizedString,
  component: JSX.Element
}

interface Route {
  url: string,
  component: JSX.Element
}
