import { Link as RouterLink, NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'

export const Link = styled(({ noUnderline: boolean, ...rest }) => <RouterLink {...rest} />)`
  position: relative;
  cursor: pointer;
  color: ${props => props.color};

  ${props => !props.noUnderline ? `
    &::before{
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      width: 0;
      height: 2px;
      background-color: currentColor;
      transition: width .5s cubic-bezier(0.25, 1, 0.5, 1);
    }

    &:hover::before{
      left: 0;
      right: auto;
      width: 100%;
    }
    ` : ''
  }
`

export const NavLink = styled(RouterNavLink)<NavLinkProps>`
  position: relative;
  cursor: pointer;
  color: currentColor;

  &:before{
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width .5s cubic-bezier(0.25, 1, 0.5, 1);
  }

  &:hover::before{
    left: 0;
    right: auto;
    width: 100%;
  }
`
