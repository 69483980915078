import Container from '../Container'
import LanguageSelector from '../LanguageSelector'
import { Link } from '../Link'

type MainNavigationProps = {
  logoUrl: string,
  openHamburgerMenu: () => void
}

const MainNavigation = ({logoUrl, openHamburgerMenu}: MainNavigationProps) => {
  return (
    <nav>
      <Container className='text-lg flex justify-between'>
        <Link to="/" noUnderline><img src={logoUrl} alt='Nordic Coding Factory' className='h-10 sm:h-12 inline-block' /></Link>
        <LanguageSelector responsive />
        {/* <MenuIcon className="inline h-10 sm:h-12 w-10 sm:w-12 cursor-pointer" onClick={openHamburgerMenu}></MenuIcon> */}
      </Container>
    </nav>
  )
}

export default MainNavigation
