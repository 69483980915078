import { BehaviorSubject } from 'rxjs'

export enum Language {
  FI = 'fi',
  EN = 'en'
}

export const defaultLanguage = Language.EN

class LanguageService {
  private _language = new BehaviorSubject<Language>(this.getLanguageFromSessionOrNavigator())
  public language$ = this._language.asObservable()

  public set language(language: Language) {
    localStorage.setItem('ncfLanguage', language)
    this._language.next(language)
  }

  private getLanguageFromSessionOrNavigator(): Language {
    return (localStorage.getItem('ncfLanguage') || navigator.language) === 'fi' ? Language.FI : Language.EN
  }
}

export const languageService = new LanguageService()
