import { Translations, useTranslations } from '../hooks/useTranslations'
import Container from './Container'

const translations: Translations = {
  privacyPolicy: {
    fi: 'Tietosuojaseloste',
    en: 'Privacy policy'
  },
  businessId: {
    fi: 'y-tunnus',
    en: 'business id'
  }
}


const Footer = () => {
  const translated = useTranslations(translations)
  return (
    <footer>
     {/*Sections:
      - logo
      - company
      - careers
      - services
     - contact*/}

        <div className='bg-slate-700'>
        <Container className='py-8 text-white'>
          <div className='text-sm sm:text-lg inline-block flex flex-row justify-between'>
            <div>
              <p>© 2023 Nordic Coding Factory</p>
              <p>{translated.businessId}: 3189472-1</p>
            </div>
            {/* <div>
              <Link to={routesConfig.privacyPolicy.url.en} color='white'>{translated.privacyPolicy}</Link>
            </div> */}
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
