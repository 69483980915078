import ContactUs from '../components/ContactUs'
import Container from '../components/Container'
import Page from '../components/Page'
import Title from '../components/Title'
import { Translations, useTranslations } from '../hooks/useTranslations'

const translations: Translations = {
  services: {
    fi: 'Palvelut',
    en: 'Services'
  }
}

const Services = () => {
  const translated = useTranslations(translations)

  return (
    <Page title={translated.services} titleBackground='bg-services'>
      <Container className='mb-24'>
        <Title size={2}>Osaamisemme</Title>
        <Title size={3}>Konsultointi</Title>
        <p>Ohjelmistokonsultointi on prosessi, jonka avulla investoinneista voidaan tehdä tietoon perustuvia päätöksiä kustannustehokkaasti. Sen avulla yritykset, jotka aikovat investoida tiettyyn ratkaisuun, saavat hyvän käsityksen sovelluksen toteutuskelpoisuudesta ja tietoa siitä, onko heidän liikeidealleen vaihtoehtoja. Lisäksi he oppivat erottautumaan markkinoilla ja hahmottavat millaisella teknologisella ratkaisulla voidaan saavuttaa paras lopputulos.</p>
        <p>Rakennamme moderneja digitaalisia palveluita. Seuraamme aktiivisesti alan kehitystä ja testaamme uusia toimintatapoja, jotta jokaisessa hankkeessa käyttämämme menetelmät palvelevat parhaiten asiakkaan tarpeita. Haemme laadukkaita kestäviä ratkaisuja, jotka kantavat pitkälle.

WEBKEHITYS
MOBIILIKEHITYS
RAJAPINNAT JA INTEGRAATIOT</p>
<p>Scrum ja ketterät menetelmät</p>
        <Title size={3}>Ohjelmistokehitys</Title>
        <p>Our Team will deliver any custom-made solution in the area of our experience or become part of your development process.
Our skills include C/C++, .NET, Java, Spring, Hybernate, SQL and NoSQL DBs, Python, Node.min.js/React, mobile development (Android/iOS), OpengGL, web development, and more.</p>
<p>Luo web-sovelluksia yrityksesi tarpeisiin käyttäen Reactin ja Angularin kaltaisia nykyaikaisia teknologioita. Sovelluskehittäjämme rakentavat huipputason ratkaisut modernia JavaScript-ekosysteemiä hyödyntäen. Avustamme asiakkaitamme aina löytämään parhaiten sopivat tekniset ratkaisut kuhunkin web-projektiin varmistaen sovelluksen nopeuden, helppokäyttöisyyden, turvallisuuden ja toimivuuden eri selaimilla.</p>
        <Title size={3}>DevOps</Title>
        <p>DevOpsin ytimenä on turhien työvaiheiden karsiminen ja kaiken toistuvan automatisointi. Autamme tiimiänne nostamaan omaa pilviosaamistaan yhteistyömme aikana.

AMAZON WEB SERVICES
GOOGLE CLOUD
AZURE
KUBERNETES</p>
<p>Hallitse tuotteesi kehittymistä ja elinkaarta ja varmista, että laatu pysyy korkealla alusta loppuun. Laadunvarmistuksen asiantuntijamme huolehtivat, että tuotteesi on turvallinen, vakaa ja toimiva. Käymme läpi tuotteelle asetetut tavoitteet ja esitämme muutosehdotuksia, jos sovellusta voitaisiin vielä parantaa</p>




        <Title size={2}>Palvelut</Title>
        <Title size={3}>Konsultointi / Työskentely osana asiakkaan tiimiä</Title>
        <p></p>
        <Title size={3}>Projektitoimitukset</Title>
        <p></p>
      </Container>

      <ContactUs />
    </Page>
  )
}

export default Services
