import { useState } from 'react'
import styled from 'styled-components'
import { useMainNavState } from '../../hooks/useMainNavState'
import { usePageScrolled } from '../../hooks/usePageScrolled'
import HamburgerMenu from './HamburgerMenu'
import MainNavigation from './MainNavigation'
import { MainNavState } from './MainNavState'

type MainNavScrolledBaseProps = {
  scrolled: boolean
}

const MainNavScrolledBase = styled.div<MainNavScrolledBaseProps>`
  opacity: ${props => props.scrolled ? '1': '0'};
  visibility: ${props => props.scrolled ? 'visible': 'hidden'};
  z-index: ${props => props.scrolled ? '50': '0'};
  transition: all .5s;
  transition-property: opacity, visibility;
`

const Header = () => {
  const pageScrolled = usePageScrolled()
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false)
  const mainNavState = useMainNavState()

  const openHamburgerMenu = () => setShowHamburgerMenu(true)
  const closeHamburgerMenu = () => setShowHamburgerMenu(false)

  return (
    <header className='fixed w-full z-50 h-20'>
      <MainNavScrolledBase scrolled={!!pageScrolled || mainNavState === MainNavState.scrolled} className='absolute w-full text-sky-700'>
        <div className='h-20 bg-white shadow-lg'></div>
        <div className='w-full top-0 absolute'>
          <MainNavigation logoUrl='/img/logo.svg' openHamburgerMenu={openHamburgerMenu}></MainNavigation>
        </div>
      </MainNavScrolledBase>

      <div className={`absolute w-full text-white`}>
        <MainNavigation logoUrl='/img/logo-alt.svg' openHamburgerMenu={openHamburgerMenu}></MainNavigation>
      </div>

      {showHamburgerMenu ? <HamburgerMenu closeCallback={closeHamburgerMenu}></HamburgerMenu> : null}
    </header>
  )
}

export default Header
