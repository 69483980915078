import { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import ContactUs from '../components/ContactUs'
import Container from '../components/Container'
import { mainNavState, MainNavState } from '../components/header/MainNavState'
import HomeServices from '../components/home/HomeServices'
import Title from '../components/Title'
import { Translations, useTranslations } from '../hooks/useTranslations'

const TopCanvas = styled.section`
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    35% {
      background-position: 100% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    65% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  background: linear-gradient(45deg, #01fafe, #63aae9, #79b6ec, #99ccff);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
`

const translations: Translations = {
  expert: {
    fi: 'Ohjelmistokehityksen asiantuntija',
    en: 'Software development expert'
  },
  intro: {
    fi: 'on suomalainen ohjelmistoalan konsultointiin keskittynyt yritys. Autamme asiakkaitamme tuottamaan laadukkaita digitaalisia palveluita.',
    en: 'is a Finnish company that specializes in software consulting. We help our clients produce high-quality digital services.'
  },
  efficientSolutionsTitle: {
    fi: 'Tehokkaita ratkaisuja',
    en: 'Efficient Solutions'
  },
  efficientSolutionsP1: {
    fi: 'Tehokkaat ja toimivat ohjelmistoratkaisut syntyvät huolellisen suunnittelun, laadukkaan koodin ja hyvän arkkitehtuurin avulla. Järjestelmän arkkitehtuuri on keskeisessä roolissa sen tehokkuudessa ja kestävyydessä, koska se määrittää järjestelmän rakenneosat, toiminnallisuudet ja rajapinnat. Laadukas koodi puolestaan mahdollistaa joustavan muokattavuuden ja skaalautuvuuden, mikä on tärkeää järjestelmän kestävyyden kannalta.',
    en: "Efficient and functional software solutions are created through careful planning, quality coding, and good architecture. The system architecture plays a central role in its efficiency and durability, as it defines the system's structural components, functionality, and interfaces. Quality code, on the other hand, enables flexible customization and scalability, which is crucial for the system's sustainability."
  },
  efficientSolutionsP2: {
    fi: 'Nordic Coding Factory panostaa merkittävästi laatuun ja arkkitehtuurin kehittämiseen. Käytämme aikaa ja resursseja järjestelmän suunnitteluun, dokumentointiin ja testaukseen. Näin varmistamme, että järjestelmät ovat suorituskykyisiä, luotettavia ja skaalautuvia. Laadukkaan ohjelmistokehityksen avulla asiakkaamme voivat saavuttaa parempia tuloksia, säästää kustannuksia ja tuottaa pitkäikäisiä järjestelmiä.',
    en: "Nordic Coding Factory places significant emphasis on software development quality and architecture. We invest time and resources in system planning, documentation, and testing to ensure that systems are performant, reliable, and scalable. With high-quality software development, our clients can achieve better results, save costs, and produce long-lasting systems."
  },
  aboutUsTitle: {
    fi: 'Meistä',
    en: "About us"
  },
  aboutUsP1: {
    fi: 'Nordic Coding Factory on pitkäaikainen ja luotettava ohjelmistoalan kumppani suomalaisille yrityksille.  Tunnemme asiakkaamme hyvin ja tiedämme, kuinka tärkeää on panostaa asiakaslähtöisyyteen, kun luodaan laadukkaita digitaalisia ratkaisuja. Meillä on yli 11 vuoden kokemus suomalaisista asiakkaista, ja olemme ylpeitä siitä, että olemme osallistuneet moniin haastaviin projekteihin.',
    en: "Nordic Coding Factory is a long-standing and reliable software industry partner for Finnish companies. We know our customers well and understand the importance of customer focus when creating high-quality digital solutions. With over 11 years of experience working with Finnish clients, we are proud to have participated in many challenging projects."
  },
  aboutUsP2: {
    fi: 'Kokemuksemme ohjelmistoalan konsultoinnista on opettanut meille, että yksi keskeisimmistä tehtävistämme on jakaa työssä kertynyttä asiantuntemusta ja osaamista organisaatiosta toiseen. Tämä on tärkeää, jotta voimme auttaa asiakkaitamme kehittämään digitaalisia ratkaisuja, jotka vastaavat heidän liiketoimintatarpeitaan. Olipa kyseessä uuden projektin aloittaminen tai jo vauhdissa olevan laajentaminen, Nordic Coding Factory on valmiina tarttumaan toimeen ja tarjoamaan kestäviä ja innovatiivisia ratkaisuja.',
    en: "Our experience in software consulting has taught us that one of our key tasks is to share the expertise and knowledge we have accumulated with other organizations. This is important so that we can help our clients develop digital solutions that meet their business needs. Whether it's starting a new project or expanding an existing one, Nordic Coding Factory is ready to take action and offer sustainable and innovative solutions."
  }
}

const Home = () => {
  const translated = useTranslations(translations)

  useEffect(() => {
    mainNavState.next(MainNavState.static)
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>Nordic Coding Factory - {translated.expert}</title>
        <meta name="description" content={`Nordic Coding Factory ${translated.intro}`} />
        <meta name="og:title" content="Nordic Coding Factory" />
        <meta name="og:description" content={`Nordic Coding Factory ${translated.intro}`} />
        {/* <meta name="og:image" content="" /> */}
        <meta name="og:url" content="https://nordiccodingfactory.fi" />
      </Helmet>

      <TopCanvas className="h-screen max-h-[76rem]">
        <Container className="h-full">
          <div className="text-white px-4 pt-24 md:pt-12 pb-10 w-full h-full grid grid-rows-1 content-center justify-items-center items-center">
            <img src="/img/logo-home.png" alt='Nordic Coding Factory' className='w-2/3 hidden md:inline-block' />
            <img src="/img/logo-home-mobile.png" alt='Nordic Coding Factory' className='w-1/2 inline-block md:hidden' />
          </div>
        </Container>
      </TopCanvas>

      <Container>
        <section className="text-black mt-16 lg:grid grid-rows-1 grid-cols-12 relative">
          <div className="text-2xl lg:text-3xl-loose font-montserratLight col-start-3 col-span-8 leading-11">
            <h1 className="font-montserratSemiBold text-sky-900 inline">Nordic Coding Factory</h1>&nbsp;
            <span className="font-montserratLight  text-sky-700">{translated.intro}</span>
          </div>
        </section>

        <section className="text-black mt-24 xl:mt-32 mb-24 grid grid-cols-1 xl:grid-cols-12 gap-y-4 relative">
          <Title size={2} className="col-span-5 xl:pr-16">{translated.efficientSolutionsTitle}</Title>
          <p className='xl:row-start-2 col-span-6 text-xl xl:pr-16'>{translated.efficientSolutionsP1}</p>
          <p className='xl:row-start-3 col-span-6 text-xl xl:pr-16'>{translated.efficientSolutionsP2}</p>
          <div className='xl:row-start-2 xl:row-span-2 xl:col-start-8 xl:col-span-6 bg-quality bg-center bg-cover h-96 xl:h-auto'></div>
        </section>

        <HomeServices />

        <section className="  text-black mt-24 xl:mt-32 mb-24 grid grid-cols-1 xl:grid-cols-2 gap-x-16 gap-y-4">
          <Title size={2} className="xl:col-span-2 pr-8">{translated.aboutUsTitle}</Title>
          <p className='text-xl'>{translated.aboutUsP1}</p>
          <p className='text-xl'>{translated.aboutUsP2}</p>
        </section>
      </Container>

      <ContactUs />
    </Fragment>
  )
}

export default Home
